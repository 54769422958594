.investments-body{
    margin: 0 0 0 0;
    padding: 80px 0;
    background-image: url('../../img/brick.jpg');
    position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.investments-body .inner {
    width: 80%;
    margin: 0 auto;
}
.investments-body .inner h2 {
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    padding-bottom: 20px;
}
.investments-body .text {
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 40px;
    box-shadow: 1px 5px 12px 5px #000c02 !important;
    text-align: center;
    border-radius: 20px;
    animation: signupFormContainer 5s 5s forwards infinite;
}
.investments-body .text .content {
    background: #FDF4E1;
    padding: 0px 25px 20px 25px;
    border-radius: 0px 0px 20px 20px;
}
.investments-body .text .upperBG {
    border-radius: 20px 20px 0px 0px;
    background-image: url('../../img/investment-bg.webp');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 150px;
    width: 100%;
}
.investments-body .text .icon {
    background: #BBD51A;
    border-radius: 50%;
    box-shadow: 1px 5px 6px 4px #001f0e9f !important;
    margin-top: -20%;
    margin-bottom: 0 !important;
    padding: 0 !important;
    z-index: 999999 !important;
}
.investments-body .text h3 {
    font-size: 15px;
    font-weight: 600;
    color: #3b2201c4;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 20px;
}
.investments-body .text .content .investment-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.investments-body .text .content .investment-details .price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.investments-body .text .content .investment-details .min {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.investments-body .text .content .investment-details .max {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.investments-body .text .content .investment-details .roi {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.investments-body .text .content .investment-details p {
    color: #3b2201c4;
    font-size: 13px;
    font-weight: 600;
    padding: 0 !important;
    margin: 0 !important;
}
.investments-body .text .content .investment-details span {
    color: #3b2201c4;
    font-size: 12px;
    font-weight: 500;
    padding: 0;
    margin: 0;
}
.investments-body .text .content .investment-details a {
    background: #00BFA6;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 9px 20px;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
    box-shadow: 1px 3px 6px 2px #000c02b6 !important;
}
.investments-body .text .content .investment-details a:hover {
    background: #BBD51A;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 9px 20px;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;
}

.investments-body .right-img {
    margin: 0px auto;
    padding: 0;
    width: 100%;
}
.investments-body .right-img img {
    width: 40%;
    margin: 0px auto !important;
}
.investments-body .right-img img:hover {
    transform: rotate(10deg);
    transition: 1s ease-in-out;
}



@media (max-width:500px) {
    .investments-body{
        padding: 70px 0;
    }
    .investments-body .inner {
        width: 95%;
        margin: 0 auto;
    }
    .investments-body .inner h2 {
        font-size: 20px;
    }
    .investments-body .text {
        width: 100%;
        margin: 15px auto !important;
        padding: 15px;
    }
    .investments-body .text h3 {
        font-size: 16px;
    }
    .investments-body .right-img img {
        width: 80%;
        margin: auto;
    }
    .bt {
        margin-top: 0px !important;
    }
}

@media (min-width:501px) AND (max-width:991px) {
    .investments-body{
        padding: 70px 0;
    }
    .investments-body .inner {
        width: 90%;
    }
    .investments-body .inner h2 {
        font-size: 19px;
    }
    .investments-body .text {
        width: 100%;
        margin: 15px auto;
        padding: 20px 30px;
    }
    .investments-body .text h3 {
        font-size: 16px;
    }
    .investments-body .right-img img {
        width: 70%;
        margin: auto;
    }
}

@media (min-width:992px) AND (max-width:1199px) {
    .investments-body{
        padding: 70px 0;
    }
    .investments-body .inner {
        width: 93%;
        margin: 0 auto;
    }
    .investments-body .text {
        width: 100%;
        padding: 15px;
    
    }
    .investments-body .text h3 {
        font-size: 16px;
    }
    .investments-body .right-img img {
        width: 60%;
        margin: auto;
    }
}