.terms {
    background-image: url('../../img/white-bg.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding: 90px 0;
}
.terms .left-pagraph {
    width: 60%;
    margin-left: 10%;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #26683C;
    box-shadow: 5px 4px 3px 5px #d1d1d1;
}
.terms .right-pagraph {
    width: 90%;
    margin: 30px auto;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #26683C;
    box-shadow: 5px 4px 3px 5px #d1d1d1;
}
.terms p {
    color: #121212;
    font-size: 15px;
    line-height: 1.7em;
}

.terms h3 {
    color: #121212;
    font-size: 20px;
    font-weight: 600;
}

.terms .span {
    font-size: 17px !important;
    font-weight: 600 !important;
}

.terms li {
    color: #121212;
    font-size: 15px;
    line-height: 1.7em;
}

@media (max-width:500px) {
    .terms .left-pagraph {
        box-sizing: border-box;
        width: 90%;
        margin-left: 0;
        margin-right: 10%;
    }
    .terms p {
        font-size: 15px;
        line-height: 1.7em;
    }
}

@media (min-width:501px) AND (max-width:991px) {
    .terms .left-pagraph {
        box-sizing: border-box;
        width: 80%;
        margin-left: 5%;
        margin-right: 10%;
    }
    .terms p {
        font-size: 15px;
        line-height: 1.7em;
    }
}