.listing-details .overview {
    color: #000;
    background: #fff;
    text-align: left;
    padding: 0;
    box-shadow: 5px 5px 3px 5px #D8D9DE;
    max-width: 100%;
    margin: 20px auto
}
.listing-details .overview h3 {
    background: #26683C;
    color: #fff;
    font-size: 17px;
    text-align: left;
    padding: 15px;
    margin: 0;
}
.listing-details .overview p {
    color: #26683C;
    text-align: left;
    padding: 15px;
    margin: 0;
    border-bottom: 1px solid #26683C;
}
.listing-details .overview a {
    color: #26683C;
    font-size: 15px;
    font-weight: 500;
}

.listing-details .overview .button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 15px;
    color: #26683C;
    font-size: 15px;
    font-weight: 500;
}

@media (max-width:500px) {
    .listing-details .overview {
        text-align: left;
        padding: 0;
        max-width: 100%;
        margin: 20px auto
    }
    .listing-details .overview h3 {
        font-size: 17px;
        text-align: left;
        padding: 15px 25px;
        margin: 0;
    }
    .listing-details .overview p {
        text-align: left;
        padding: 15px 25px;
        margin: 0;
    }
    .listing-details .overview a {
        font-size: 15px;
        font-weight: 500;
    }
    .listing-details .overview .button {
        padding: 15px 25px;
    }    
}

@media (min-width:992px) AND (max-width:1199px) {
    .listing-details .overview {
        color: #000;
        background: #fff;
        text-align: left;
        padding: 0;
        box-shadow: 5px 5px 3px 5px #D8D9DE;
        max-width: 100%;
        margin: 10px auto
    }
    .listing-details .overview h3 {
        background: #26683C;
        color: #fff;
        font-size: 15px;
        text-align: left;
        padding: 15px;
        margin: 0;
    }   
}