.listing-details .details {
    box-shadow: 5px 5px 3px 5px #D8D9DE;
    background: #fff;
    text-align: left;
    padding: 25px 20px;
    margin: 30px 0;
    border: 1px solid #26683C;
    border-radius: 10px;
}
.listing-details .details h3 {
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    width: 100%;
    color: #26683C;
    padding: 10px 0;
    margin: 0;
    text-transform: uppercase;
}
.listing-details .details p {
    color: #121212;
    margin: 0;
    border: 0;
    font-size: 15px;
    line-height: 1.5em;
    padding: 5px 0;
}


@media (max-width:500px) {
    .listing-details .details p {
        text-align: center;
    }
}

@media (min-width:501px) AND (max-width:991px) {
    .listing-details .details {
        padding: 25px 30px;
    }
    .listing-details .details p {
        text-align: center;
    }
}

@media (min-width:992px) AND (max-width:1199px) {
    .listing-details .details {
        padding: 25px 30px;
    }
}