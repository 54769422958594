/* Faq main styles */
.career-main {
    background: transparent;
    padding: 70px;
}

.career-main p {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 10px;
}
.career-main .main-container {
    width: 60%;
    margin: 0 auto;
    background:#235A37;
    border-radius: 20px;
    padding: 10px 20px !important;
    box-shadow: 5px 4px 3px 5px #d1d1d1;
    border: 1px dashed #fff;
}

.career-main .main-container .accordion {
    padding: 0px;
}

.career-main .main-container .accordion .accordion-item{
    border: 0;
    margin: 25px auto;
    border-radius: 14px;
    box-shadow: 1px 5px 12px 2px #7181732d;
    background: #26683C;
}

.career-main .main-container .accordion .accordion-button{
    padding: 13px 25px;
    background: #26683C;
    border: 1px dashed #ffffffb2;
    border-top: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 12px;
    transition: 0.5s ease-in-out;
    box-shadow: 7px 7px 9px #12311d;
}

.career-main .main-container .accordion .accordion-button:not(.collapsed){
    border-radius: 20px;
}

.career-main .main-container .accordion .accordion-body{
    border-top: 0;
    font-size: 13px;
    line-height: 1.6em;
    color: #fff;
}

@media (max-width:500px) {
    .career-main {
        padding: 70px 30px;
    }
    .career-main .main-container-bg {
        width: 100%;
    }
    .career-main .main-container {
        padding: 30px 20px;
    }
    .career-main .main-container .accordion .accordion-button{
        font-size: 13px;
    }
}

@media (min-width:501px) AND (max-width:991px) {
    .career-main {
        padding: 75px 50px;
    }
    .career-main .main-container {
        width: 100%;
    }
    .career-main .main-container .accordion .accordion-button{
        font-size: 14px;
    }
}

@media (min-width:992px) AND (max-width:1199px) {
    .career-main {
        padding: 75px 60px;
    }
    .career-main .main-container {
        width: 75%;
    }
    .career-main .main-container .accordion .accordion-button{
        font-size: 14px;
    }
}

@media (min-width:1200px) AND (max-width:1400px) {
    .career-main {
        padding: 75px;
    }
    .career-main .main-container {
        width: 65%;
    }
}

