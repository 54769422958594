/* Contact main styles */
.contact-main {
    background: transparent;
    /* background-image: url('../../img/white-bg.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; */
    padding: 70px;
    margin: 0 !important;
}
.contact-main p {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 10px;
}

.contact-main-container {
    width: 70%;
    margin: 0 auto !important;
    background:#235A37;
    border-radius: 20px;
    padding: 40px 40px !important;
    box-shadow: 5px 4px 3px 5px #d1d1d1;
    border: 1px dashed #fff;
}

.contact-bx label {
    font-size: 13px;
    font-weight: 600;
    display: block;
    margin-bottom: 3px;
    color: #fff;
    margin-top: 15px;
}
.contact-bx input, select, textarea, .disabled {
    width: 100%;
    display: block;
    background-color: #26683C;
    padding: 11px 15px;
    border: 1px dashed #ffffff67;
    border-spacing: 1em;
    color: #fff;
    border-radius: 10px;
    box-shadow: 7px 7px 9px #0e2717 !important;
    font-size: 13px !important;
}
.contact-bx option {
    height: 200px !important;
    padding: 50px !important;
    font-size: 13px;
    margin: 20px !important;
    display: block;
}
.contact-bx input::placeholder{
    color: #fff;
    opacity: 0.5;
    font-size: 13px;
}
.contact-bx input:hover {
    animation: signupFormRow 1s;
}
.contact-bx button {
    width: 50%;
    display: block;
    background-color: #BBD51A;
    padding: 15px 20px;
    margin: 40px auto;
    border-spacing: 1em;
    color: #163b23;
    border-radius: 30px;
    box-shadow: 2px 2px 9px #163b23;
    font-size: 15px;
    font-weight: 600;
}

.contact-main-container .paragraph {
    color: #fff;
    text-align: center;
    padding-bottom: 15px !important;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}
.contact-main-container a {
    display: inline-block !important;
    color: #fff;
    text-align: center;
    padding-bottom: 15px !important;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}


@media (max-width:500px) {
    .contact-main {
        padding: 70px 30px;
    }
    .contact-main-container {
        width: 100%;
        padding: 30px 20px !important;
    }
    .contact-bx .message {
        margin-top: 15px;
    }
    .contact-bx button {
        width: 70%;
        border-radius: 15px;
    }

    
}

@media (min-width:501px) AND (max-width:991px) {
    .contact-main {
        padding: 75px 50px;
    }
    .contact-main-container {
        width: 100%;
        padding: 30px 20px !important;
    }
}

@media (min-width:992px) AND (max-width:1199px) {
    .contact-main {
        padding: 75px 60px;
    }
    .contact-main-container {
        width: 75%;
    }
}

@media (min-width:1200px) AND (max-width:1400px) {
    .contact-main {
        padding: 75px;
    }
    .contact-main-container {
        width: 65%;
    }
}

