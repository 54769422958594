/************ Navbar Css ************/

nav.navbar {
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  background: #021808;
  /* background: transparent !important; */
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #021808;
  /* background: transparent !important; */
}

nav.navbar .navbar-brand {
  width: 8%;
  margin-right: 13%;
}

nav.navbar .navbar-nav a.navbar-link,
a#basic-nav-dropdown {
  font-weight: 400;
  color: #aef5c5 !important;
  letter-spacing: 0.8px;
  padding: 0px 15px;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.9;
}
nav.navbar .navbar-nav a.navbar-drop-link {
  font-weight: 400;
  color: #cafada !important;
  letter-spacing: 0.8px;
  padding: 20px 7px;
  font-size: 16px;
  font-weight: 500;
  opacity: 0.9;
  overflow-y: hidden !important;
}
nav.navbar .navbar-nav a.navbar-link:hover,
nav.navbar .navbar-nav a.navbar-link.active,
#basic-nav-dropdown:hover,
#basic-nav-dropdown.active {
  opacity: 1;
  font-weight: 600;
}

#basic-nav-dropdown {
  padding: 10px;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: transparent;
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid #aef5c5;
}
.social-icon a::before {
  content: '';
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #001f0e;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
  opacity: 0.1;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 50%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg)
    brightness(95%) contrast(86%);
}

.navbar-text .action-buttons {
  display: inline-flex;
}

.navbar-text a.login {
  font-weight: 600;
  color: #aef5c5;
  padding: 10px 10px;
  font-size: 15px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.navbar-text a.login span {
  z-index: 1;
}
.navbar-text a.login::before {
  content: '';
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #001f0e;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
}
.navbar-text a.login:hover {
  color: #fff;
  font-weight: 400;
}
.navbar-text a.login:hover::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
}

.navbar-text a.signup {
  font-weight: 500;
  color: #fff;
  padding: 8px 20px;
  font-size: 15px !important;
  margin-left: 3px;
  position: relative;
  background-color: #001f0e;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  border: 1px solid #aef5c5;
  display: flex;
  align-items: center;
}
.navbar-text a.signup span {
  z-index: 1;
}
.navbar-text a.signup::before {
  content: '';
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff !important;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
}
.navbar-text a.signup:hover {
  color: #121212;
}
.navbar-text a.signup:hover::before {
  content: 'LOGIN';
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  color: #26683c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border: 1px solid #26683c;
}
.navbar-text a.dashboard:hover::before {
  content: 'DASHBOARD' !important;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  color: #26683c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border: 1px solid #26683c;
}

nav.navbar .navbar-toggler {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: 1px solid #aef5c5;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 22px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #aef5c5;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 1px solid #aef5c5;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 1.5px;
  background-color: #aef5c5;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #121212;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #121212;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon {
  border-color: transparent;
}

@media (max-width: 500px) {
  .navbar {
    box-sizing: border-box;
  }
  nav.navbar .navbar-brand {
    width: 15%;
  }

  nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon {
    border-color: transparent;
    background-color: #121212;
  }
  nav.navbar .navbar-nav a.navbar-link,
  #basic-nav-dropdown {
    margin-top: 15px;
    font-size: 14px;
    color: #26683c !important;
    overflow-y: hidden !important;
  }

  nav.navbar .navbar-nav a.navbar-drop-link {
    color: #26683c !important;
    overflow-y: hidden !important;
  }

  .social-icon a {
    width: 32px;
    height: 32px;
  }
  .social-icon a::before {
    width: 32px;
    height: 32px;
  }
  .social-icon a img {
    width: 60%;
  }

  .navbar-text button.login {
    padding: 8px 25px;
    margin-left: 0;
  }

  .navbar-text button.signup {
    padding: 8px 25px;
    margin-left: 2px;
  }

  .navbar-text a.login {
    font-size: 13px;
  }

  .navbar-text a.signup {
    font-size: 13px !important;
  }

  span.navbar-text {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
  }

  .navbar-text .action-buttons {
    display: flex;
    align-items: flex-start;
  }

  .sm-dark {
    background-color: #ffffff;
  }
}

@media (min-width: 501px) AND (max-width: 991px) {
  nav.navbar .navbar-brand {
    width: 12%;
  }

  nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon {
    border-color: transparent;
    background-color: #121212;
  }

  .social-icon a {
    width: 32px;
    height: 32px;
  }
  .social-icon a::before {
    width: 32px;
    height: 32px;
  }
  .social-icon a img {
    width: 60%;
  }

  .navbar-text button.login {
    padding: 8px 25px;
    margin-left: 0;
  }

  .navbar-text button.signup {
    padding: 8px 25px;
    margin-left: 2px;
  }

  .navbar-text a.login {
    font-size: 13px;
  }

  .navbar-text a.signup {
    font-size: 13px !important;
  }

  nav.navbar .navbar-nav a.navbar-link,
  #basic-nav-dropdown {
    margin-top: 15px;
    font-size: 14px;
    color: #26683c !important;
    overflow-y: hidden !important;
  }

  nav.navbar .navbar-nav a.navbar-drop-link {
    color: #26683c !important;
    overflow-y: hidden !important;
  }

  span.navbar-text {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .navbar-text .action-buttons {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0px;
  }

  .sm-dark {
    background-color: #ffffff;
  }
}

@media (min-width: 992px) AND (max-width: 1199px) {
  nav.navbar .navbar-brand {
    width: 9%;
    margin-right: 7%;
  }

  .social-icon a {
    width: 32px;
    height: 32px;
  }
  .social-icon a::before {
    width: 32px;
    height: 32px;
  }
  .social-icon a img {
    width: 60%;
  }

  .navbar-text .action-buttons .login {
    padding: 8px 10px !important;
    margin-left: 0;
  }

  .navbar-text .action-buttons .signup {
    padding: 8px 10px !important;
    margin-left: 2px;
  }

  nav.navbar .navbar-nav a.navbar-link,
  #basic-nav-dropdown {
    padding: 0 10px;
    font-size: 14px;
  }

  .navbar-text a.login {
    font-size: 13px;
  }

  .navbar-text a.signup {
    font-size: 13px !important;
  }
}

@media (min-width: 1200px) AND (max-width: 1400px) {
  nav.navbar .navbar-brand {
    margin-right: 11%;
  }
  .social-icon a {
    width: 33px;
    height: 33px;
  }
  .social-icon a::before {
    width: 33px;
    height: 33px;
  }
  .social-icon a img {
    width: 60%;
  }

  .navbar-text button.login {
    padding: 8px 25px;
    margin-left: 0;
  }

  .navbar-text button.signup {
    padding: 8px 25px;
    margin-left: 2px;
  }

  nav.navbar .navbar-nav a.navbar-link,
  #basic-nav-dropdown {
    padding: 0 10px;
    font-size: 14px;
  }

  .navbar-text a.login {
    font-size: 13px;
  }

  .navbar-text a.signup {
    font-size: 13px !important;
  }
}
