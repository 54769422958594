/* Default CSS */
@font-face {
  font-family: Raleway;
  src: url('../font/Raleway-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Raleway;
  src: url('../font/Raleway-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Raleway;
  src: url('../font/Raleway-Light.ttf');
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden !important;
  position: relative;
  background-color: #fff !important;
  color: #ffffff !important;
  font-family: 'Raleway', sans-serif !important;
}

::-webkit-scrollbar {
  width: 13px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb {
  background: #001f0e;
  border-radius: 10px;
}

.scroll-top {
  position: fixed;
  padding: 12px;
  font-size: 18px;
  bottom: 40px;
  left: 20px;
  background-color: #aef5c5;
  color: #fff;
  text-align: center;
  font-weight: 500;
  border-radius: 50%;
}
.chat-icon {
  height: 48px !important;
  width: 48px !important;
  /* margin-bottom: 0.3rem; */
  bottom: 40px;
  right: 20px;
  overflow-x: hidden;
  overflow-y: hidden;
}
@media (max-width: 500px) {
  .scroll-top {
    position: fixed;
    padding: 8px;
    font-size: 15px;
    bottom: 20px;
    left: 15px;
    background-color: #aef5c5;
    color: #fff;
    text-align: center;
    font-weight: 500;
    border-radius: 20px;
  }
  .chat-icon {
    padding: 0px;
    height: 38px !important;
    width: 38px !important;
    /* margin-bottom: 0.3rem; */
    bottom: 15px !important;
    right: 15px !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a {
  text-decoration: none !important;
}

p {
  font-size: 13px;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
