/* Home services section style */

.home-services {
    padding: 100px 0;
    background-image: url('../../img/brick.jpg');
    position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.services-header {
    padding: 90px 0;
    background-image: url('../../img/brick.jpg');
    position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.home-services h3 {
    text-align: center;
    padding-bottom: 0px;
    font-weight: 600;
}
.home-services p {
    font-size: 14px;
    text-align: center;
    padding-bottom: 60px;
    font-weight: 500;
}
.home-services .left-img {
    margin: auto;
    padding: 0 0px 0 150px;
}
.home-services .left-img img {
    width: 90%;
}
.home-services .left-img img:hover {
    transform: rotate(10deg);
    transition: 1s ease-in-out;
}
.home-services .main-bx{
    padding: 0 50px;
}
.home-services .main-bx .service-bx{
    width: 80%;
    height: 60px;
    background: #00BFA6;
    padding-left: 10px;
    border-radius: 7px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}
.home-services .main-bx .serviceSlideIn {
    animation: serviceSlideIn 1.5s forwards;
}
.home-services .main-bx .service-bx:hover{
    transform: scale(1.1);
    transition: 2s ease-out;
    color: #fff !important;
}
.home-services .main-bx .service-bx img {
    width: 15%;
    padding-right: 12px;
}



@media (max-width:500px) {
    .home-services {
        padding: 90px 0;
        overflow-x: hidden !important;
    }
    .services-header {
        padding: 50px 0;
    }
    .home-services p {
        padding-bottom: 20px;
    }
    .home-services .left-img {
        width: 75%;
        margin: 10px auto;
        padding: 10px;
        text-align: center;
    }
    .home-services .main-bx{
        padding: 30px 40px 20px 40px;
    }
    .home-services .main-bx .service-bx{
        width: 100%;
    }
    .home-services .main-bx .service-bx:hover{
        transform: scale(1.1);
        transition: 2s ease-out;
        color: #000;
    }
    .home-services .main-bx .service-bx img {
        width: 17%;
    }
}

@media (min-width:501px) AND (max-width:991px) {
    .home-services {
        padding: 90px 0;
    }
    .services-header {
        padding: 50px 0;
    }
    .home-services p {
        padding-bottom: 20px;
    }
    .home-services .left-img {
        width: 75%;
        margin: 10px auto;
        padding: 10px;
        text-align: center;
    }
    .home-services .main-bx{
        padding: 30px 40px 20px 40px;
    }
    .home-services .main-bx .service-bx{
        width: 100%;
    }
    .home-services .main-bx .service-bx:hover{
        transform: scale(1.1);
        transition: 2s ease-out;
        color: #000;
    }
    .home-services .main-bx .service-bx img {
        width: 17%;
    }
}

@media (min-width:992px) AND (max-width:1199px) {
    .home-services {
        padding: 83px 0;
    }
    .services-header {
        padding: 50px 0;
    }
    .home-services .left-img {
        margin: auto;
        padding: 0 0px 0 75px;
    }
    .home-services .left-img img {
        width: 100%;
    }
    .home-services .main-bx .service-bx{
        width: 93%;
    }
}

@media (min-width:1200px) AND (max-width:1400px) {
    .home-services {
        padding: 83px 0;
    }
    .home-services .left-img {
        margin: auto;
        padding: 0 0px 0 75px;
    }
    .home-services .left-img img {
        width: 90%;
    }
    .home-services .main-bx .service-bx{
        width: 93%;
    }
}

