.listing-details .spec {
    box-shadow: 5px 5px 3px 5px #D8D9DE;
    border: 1px solid #26683C;
    border-radius: 10px;
    background: #fff;
    text-align: left;
    padding: 25px 30px;
    margin: 20px 0;
}
.listing-details .spec-row {
    display: flex;
    justify-content: space-between;
    color: #121212;
    padding: 5px 0;
}
.listing-details .spec li {
    width: 33%;
    text-align: left;
    display: inline-block;
    color: #121212;
    list-style: none;
    line-height: 2em;
    font-size: 14px;
    font-weight: 600;
}
.listing-details .spec li span {
    font-weight: 500;
}

@media (max-width:500px) {
    .listing-details .spec {
        box-shadow: 5px 5px 3px 5px #D8D9DE;
        background: #fff;
        text-align: left;
        padding: 25px 30px;
        margin: 20px 0;
    }
    .listing-details .spec-row {
        display: block;
        padding: 0;
    }
    .listing-details .spec li {
        width: 100%;
        display: block;
        line-height: 2.5em;
    }
    .listing-details .spec li span {
        font-weight: 500;
    }
}

@media (min-width:501px) AND (max-width:991px) {
    .listing-details .spec {
        box-shadow: 5px 5px 3px 5px #D8D9DE;
        background: #fff;
        text-align: left;
        padding: 25px 30px;
        margin: 20px 0;
    }
    .listing-details .spec-row {
        display: block;
        padding: 0;
    }
    .listing-details .spec li {
        width: 100%;
        display: block;
        padding: 6px 0;
    }
    .listing-details .spec li span {
        font-weight: 500;
    }
}

@media (min-width:992px) AND (max-width:1199px) {
    .listing-details .spec {
        padding: 25px 10px 25px 25px;
    }

}