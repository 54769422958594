/* Banner style */

.home-banner {
  margin-top: 86px;
  padding: 0px;
  background: #031a0aea;
  position: relative;
}
.vid-bg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -100;
  position: fixed;
}
.home-banner .home-banner-main {
  padding: 200px 0px 180px 0px !important;
  background: transparent;
  position: relative;
}
.home-banner .banner-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin: 0px auto;
}

.home-banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  border: 1px solid #9aebb3;
  color: #aef5c5;
  font-size: 16px;
  margin-bottom: 20px;
  display: inline-block;
  animation: taglineUpdown 2s linear infinite;
}
.home-banner h3 {
  font-size: 38px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.3;
  margin-bottom: 20px;
  display: block;
  text-align: center;
}
.home-banner p {
  color: #ffffffbe !important;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.5em;
  width: 96%;
}
.app-buttons {
  padding-top: 50px !important;
  text-align: center;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-buttons-img {
  padding: 20px 0;
  border-top: 2px solid #fff;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  margin: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-buttons p {
  color: #fff !important;
  font-size: 17px;
  font-weight: 500;
  padding-bottom: 5px !important;
  margin: 0px;
}
.app-buttons img {
  width: 45%;
  margin: 0;
}

@media (max-width: 500px) {
  .home-banner {
    margin-top: 36px;
    box-sizing: border-box;
    margin-right: 0 !important;
    overflow-x: hidden;
  }
  .home-banner .home-banner-main {
    padding: 150px 20px 90px 20px !important;
    margin: 0 !important;
  }
  .home-banner .banner-inner {
    width: 90%;
    align-items: flex-start;
  }
  .home-banner h3 {
    font-size: 22px;
    letter-spacing: 1px;
    line-height: 1.5em;
    text-align: start;
  }
  .home-banner p {
    width: 100%;
    font-size: 13px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    text-align: start;
  }
  .home-banner .tagline {
    padding: 8px;
    font-size: 14px;
  }
  .app-buttons {
    padding-top: 20px !important;
    text-align: center;
    width: 80%;
    margin: 0px auto !important;
  }
  .app-buttons p {
    text-align: center;
  }
  .app-buttons-img {
    border-top: 1px solid #fff;
  }
}
@media (min-width: 501px) AND (max-width: 991px) {
  .home-banner {
    margin-top: 55px;
  }
  .home-banner .home-banner-main {
    padding: 150px 5% 70px 5% !important;
  }
  .home-banner .banner-inner {
    width: 85%;
  }

  .home-banner h3 {
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 1.5em;
  }
  .home-banner p {
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 1.5em;
  }

  .home-banner .tagline {
    padding: 8px;
    font-size: 14px;
    letter-spacing: 0.8px;
  }
  .app-buttons {
    padding-top: 20px !important;
    text-align: center;
    width: 80%;
    margin: 0px auto !important;
  }
  .app-buttons p {
    text-align: center;
  }

  .app-buttons {
    text-align: center;
    width: 60%;
    margin: 10px auto !important;
  }
  .app-buttons-img {
    border-top: 2px solid #fff;
  }
}

@media (min-width: 992px) AND (max-width: 1199px) {
  .home-banner {
    margin-top: 73px;
  }
  .home-banner .home-banner-main {
    padding: 130px 0px 80px 0px !important;
  }
  .home-banner .banner-inner {
    width: 60%;
    margin: 0px auto;
  }
  .home-banner h3 {
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 20px;
    display: block;
  }
  .home-banner p {
    font-size: 14px;
  }
  .app-buttons {
    padding-top: 30px !important;
    text-align: center;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .app-buttons-img {
    padding: 13px 0;
    margin: 0px !important;
  }
  .app-buttons img {
    width: 40%;
    margin: 0;
  }
}

@media (min-width: 1200px) AND (max-width: 1500px) {
  .home-banner {
    margin-top: 75px;
  }
  .home-banner .home-banner-main {
    padding: 140px 10px 100px 100px !important;
  }
  .home-banner .banner-inner {
    width: 65%;
    margin: 0px auto;
  }
  .home-banner h3 {
    font-size: 23px;
  }
  .home-banner p {
    color: #ffffffbe !important;
    font-size: 14px;
  }
}
