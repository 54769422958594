.about-achievements {
    padding: 100px 0px;
    background: #fff;
}

.about-achievements .text {
    padding: 30px;
    background: #00bfa613;
    margin-top: 150px;
    border: 1px solid #001F0E;
    border-radius: 10px;
}

.about-achievements .text h3{
    font-size: 27px;
    font-weight: 500;
    color: #121212;
    padding-bottom: 10px;
}

.about-achievements .text p{
    font-size: 15px;
    font-weight: 500;
    line-height: 1.6em;
    color: #535353;
}

/* .about-achievements .img {
    padding: 0;
    border-radius: 15px;
    background: #E6E6E6;
    box-shadow: 5px 6px 3px 6px #7181735b;
} */

.about-achievements .img img {
    background: #fff;
    border: 1px solid #0139CB;
    border-radius: 15px;
    box-shadow: 5px 6px 3px 6px #7181735b;
}


@media (max-width:500px) {
    .about-achievements {
        padding: 100px 20px 30px 20px;
        background: #fff;
    }
    
    .about-achievements .text {
        width: 95%;
        padding: 30px;
        background: #00bfa613;
        margin: 40px auto;
        border: 1px solid #001F0E;
        border-radius: 10px;
    }
    
    .about-achievements .text h3{
        text-align: center;
        font-size: 24px;
    }
    
    .about-achievements .text p{
        font-size: 13px;
        text-align: center;
        font-weight: 500;
        line-height: 1.6em;
    }
    
    .about-achievements .img {
        width: 85%;
        margin: 0px auto;
        padding: 0;
        border-radius: 15px;
        background: #E6E6E6;
        box-shadow: 5px 6px 3px 6px #7181735b;
    }
    
    .about-achievements .img img {
        background: #fff;
        border: 1px solid #0139CB;
        border-radius: 15px;
    }
}

@media (min-width:501px) AND (max-width:991px) {
    .about-achievements {
        padding: 250px 20px 70px 20px;
        background: #fff;
    }
    
    .about-achievements .text {
        width: 95%;
        padding: 30px;
        background: #00bfa613;
        margin: 40px auto;
        border: 1px solid #001F0E;
        border-radius: 10px;
    }
    
    .about-achievements .text h3{
        text-align: center;
        font-size: 24px;
    }
    
    .about-achievements .text p{
        font-size: 13px;
        text-align: center;
        font-weight: 500;
        line-height: 1.6em;
    }
    
    .about-achievements .img {
        width: 85%;
        margin: 0px auto;
        padding: 0;
        border-radius: 15px;
        background: #E6E6E6;
        box-shadow: 5px 6px 3px 6px #7181735b;
    }
    
    .about-achievements .img img {
        background: #fff;
        border: 1px solid #0139CB;
        border-radius: 15px;
    }
}

@media (min-width:992px) AND (max-width:1199px) {
    .about-achievements {
        padding: 90px 20px 70px 20px;
        width: 80%;
        margin: 0 auto;
    }
    
    .about-achievements .text {
        padding: 20px 20px 0 20px;
        background: #00bfa613;
        margin-top: 70px;
        border: 1px solid #001F0E;
        border-radius: 10px;
    }
    
    .about-achievements .text h3{
        font-size: 27px;
        font-weight: 500;
        color: #121212;
        padding-bottom: 10px;
    }
    
    .about-achievements .text p{
        font-size: 14px;
        font-weight: 500;
        line-height: 1.6em;
        color: #535353;
        padding: 0;
    }
    .about-achievements .img img {
        background: #fff;
        border: 1px solid #0139CB;
        border-radius: 15px;
    }
}