/* Faq main styles */
.faq-main {
    background: transparent;
    padding: 70px;
}
.faq-main p {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 10px;
}
.faq-main .main-container {
    width: 70%;
    margin: 0 auto;
    background:#235A37;
    border-radius: 20px;
    padding: 10px 20px !important;
    box-shadow: 5px 4px 3px 5px #d1d1d1;
    border: 1px dashed #fff;
}
.faq-main .main-container .accordion {
    padding: 0px;
}
.faq-main .main-container .accordion .accordion-item{
    border: 0;
    margin: 25px auto;
    border-radius: 14px;
    background: #26683C;
}
.faq-main .main-container .accordion .accordion-button{
    padding: 15px 20px;
    background: #26683C;
    border: 1px dashed #ffffffb2;
    border-top: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    border-radius: 12px;
    transition: 0.5s ease-in-out;
    box-shadow: 7px 7px 9px #12311d;
}
.faq-main .main-container .accordion .accordion-button:not(.collapsed){
    border-radius: 20px;
}
.faq-main .main-container .accordion .accordion-body{
    border-top: 0;
    font-size: 13px;
    line-height: 1.6em;
    color: #fff;
    box-shadow: 7px 7px 9px #12311d;
}

.faq-main .main-container .accordion .accordion-body .career-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.faq-main .main-container .accordion .accordion-body .career-inner p {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 0;
    margin: 0;
}
.faq-main .main-container .accordion .accordion-body .inner-span {
    color: #BBD51A !important;
}
.faq-main .main-container .accordion .accordion-body .span {
    text-decoration: underline;
    color: #BBD51A !important;
    padding-right: 10px;
}
.faq-main .main-container .accordion .accordion-body .career-inner a {
    padding: 10px 35px !important;
    background: #BBD51A;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    border-radius: 15px;
}

@media (max-width:500px) {
    .faq-main {
        padding: 70px 25px;
    }
    .faq-main .main-container {
        width: 100%;
    }
    .faq-main .main-container {
        padding: 30px 20px;
    }
    .faq-main .main-container .accordion .accordion-button{
        font-size: 13px;
    }
    .faq-main .main-container .accordion .accordion-body .career-inner .span {
        padding-right: 5px;
    }
    .faq-main .main-container .accordion .accordion-body .career-inner a {
        padding: 8px 20px !important;
        border-radius: 10px;
    }
}

@media (min-width:501px) AND (max-width:991px) {
    .faq-main {
        padding: 75px 50px;
    }
    .faq-main .main-container {
        width: 100%;
    }
    .faq-main .main-container .accordion .accordion-button{
        font-size: 14px;
    }
    .faq-main .main-container .accordion .accordion-body .career-inner .span {
        padding-right: 5px;
    }
    .faq-main .main-container .accordion .accordion-body .career-inner a {
        padding: 8px 20px !important;
        border-radius: 10px;
    }
}

@media (min-width:992px) AND (max-width:1199px) {
    .faq-main {
        padding: 75px 60px;
    }
    .faq-main .main-container {
        width: 75%;
    }
    .faq-main .main-container .accordion .accordion-button{
        font-size: 14px;
    }
}

@media (min-width:1200px) AND (max-width:1400px) {
    .faq-main {
        padding: 75px;
    }
    .faq-main .main-container {
        width: 65%;
    }
}

