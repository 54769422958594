.project-details {
    background-image: url('../../img/white-bg.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding: 90px 0;
    margin-top: 86px;
}
.project-details h3 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    padding: 0;
    margin: 0;
}
.project-details p {
    color: #000;
    background: #fff;
    font-size: 15px;
    text-align: center;
    padding: 40px 50px;
    box-shadow: 5px 5px 3px 5px #D8D9DE;
    max-width: 60%;
    margin: 30px auto;
    line-height: 1.5em; 
}
.project-details .sliderWrap {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.project-details .btnClose, .project-details .btnNext, .project-details .btnPrev {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 9999;
}
.project-details .btnClose, .project-details .btnNext:hover, .project-details:hover .btnPrev:hover {
    opacity: 1;
}
.project-details .btnClose {
    top: 12%;
    right: 40px;
}
.project-details .btnNext {
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
}
.project-details .btnPrev {
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
}
.project-details .fullScreenImage {
    width: calc(100% - 300px);
    height: calc(100% - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.project-details .fullScreenImage img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.galleryWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    margin: 0 auto;
}
.galleryWrap .single {
    margin: 10px;
    height: 250px;
    width: 280px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 5px 5px 3px 5px #D8D9DE;
}
.galleryWrap .single img {
    max-width: 100%;
    height: 100%;
    border-radius: 10px;
}
.galleryWrap .single img:hover {
    transform: scale(1.02);
    transition: 0.4s ease-out;
}


@media (max-width:500px) {
    .project-details {
        margin-top: 36px;
    }
    .project-details h3 {
        font-size: 18px;
    }
    .project-details p {
        padding: 30px 20px;
        max-width: 80%;
        margin: 40px auto;
    }
    .project-details .btnClose, .project-details .btnNext, .project-details .btnPrev {
        opacity: 0.8;
    }
    .project-details .btnClose {
        top: 8%;
        right: 15px;
    }
    .project-details .btnNext {
        top: 50%;
        right: 15px;
    }
    .project-details .btnPrev {
        top: 50%;
        left: 15px;
    }
    .project-details .fullScreenImage {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
    }
    .galleryWrap {
        gap: 10px;
        max-width: 100%;
    }
    .galleryWrap .single {
        margin: 3px;
        height: 150px;
        width: 150px;
        box-shadow: 5px 3px 3px 3px #D8D9DE;
    }
}

@media (min-width:501px) AND (max-width:991px) {
    .project-details {
        margin-top: 55px;
    }
    .project-details h3 {
        font-size: 18px;
    }
    .project-details p {
        padding: 30px 30px;
        max-width: 80%;
        margin: 40px auto;
    }
    .project-details .btnClose, .project-details .btnNext, .project-details .btnPrev {
        opacity: 0.8;
    }
    .project-details .btnClose {
        top: 8%;
        right: 15px;
    }
    .project-details .btnNext {
        top: 50%;
        right: 15px;
    }
    .project-details .btnPrev {
        top: 50%;
        left: 15px;
    }
    .project-details .fullScreenImage {
        width: calc(100% - 30px);
        height: calc(100% - 30px);
    }
    .galleryWrap {
        gap: 10px;
        max-width: 100%;
    }
    .galleryWrap .single {
        margin: 3px;
        height: 150px;
        width: 150px;
        box-shadow: 5px 3px 3px 3px #D8D9DE;
    }
}

@media (min-width:992px) AND (max-width:1199px) {
    .project-details {
        margin-top: 73px;
    }
}

@media (min-width:1200px) AND (max-width:1400px) {
    .project-details {
        margin-top: 75px;
    }
}