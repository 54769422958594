/* Animations */

@keyframes signupFormRow {
    0%{transform:translateX(-10px)}
    50%{transform:translateX(10px)}
    100%{transform:translateX(0px)}
}

@keyframes signupFormContainer {
    0%{transform:translateX(-10px)}
    10%{transform:translateX(10px)}
    20%{transform:translateX(0px)}
    100%{transform:translateX(0px)}
}

@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}

@keyframes taglineUpdown {
    0% {
        transform: translateY(-10px);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}
@keyframes rotateFull {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotateFullBackward {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes serviceSlideIn {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes homeZoom {
    0% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(0.9);
    }
}


@keyframes dancingSlide {
    0% {
        transform: translateX(0px);
    }
    20% {
        transform: translateX(-20px);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateX(30px);
    }
    80% {
        transform: translateY(30px);
    }
    0% {
        transform: translateX(0px);
    }
}

@keyframes fadeInSlideUp {
    0% {
        transform: translateY(20%);
        opacity: 0;
    }
    50% {
        transform: translateY(10%);
        opacity: 0.5;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

