/* Signup styles */

.signup-screen .nav-link {
  display: inline-flex;
}

.signup-screen {
  margin-top: 86px;
  padding: 0px;
  background-image: url('../../img/brick.jpg');
  position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: hidden !important;
}

.signup-screen .signup-left {
  padding: 230px 10px 100px 180px;
  background-image: url('../../img/lemon-angle-bg.svg');
  position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
  overflow-y: hidden !important;
}

.signup-screen .signup-left img {
  width: 70%;
  text-align: center;
  animation: updown 3s linear infinite;
  overflow-y: hidden !important;
}
.signup-screen .signup-left p {
  font-size: 16px;
  font-weight: 600;
}
.signup-screen .signup-left p span {
  font-size: 22px;
  font-weight: 600;
  padding-top: 7px;
  display: block;
}

.signup-screen .signin-right {
  padding: 200px 0px 120px 20px;
  background: transparent;
}
.signup-screen .signup-right {
  padding: 120px 0px 120px 20px;
  background: transparent;
}
.signup-screen .signup-bx {
  width: 55%;
  background-color: #235a37 !important;
  padding: 40px 33px 55px 33px;
  border-radius: 20px;
  animation: signupFormContainer 5s 5s forwards infinite;
  box-shadow: 1px 8px 32px 0 #000301;
  background: transparent;
}

.signup-screen .signup-bx label {
  font-size: 13px;
  font-weight: 500;
  display: block;
  margin-bottom: 3px;
  margin-top: 15px;
}

.signup-screen .signup-bx input {
  width: 100%;
  display: block;
  background-color: #26683c;
  padding: 11px 15px;
  border: 1px dashed #ffffff67;
  border-spacing: 1em;
  color: #fff;
  border-radius: 10px;
  box-shadow: 7px 7px 9px #12311d;
  font-size: 13px;
}

.signup-screen .signup-bx input::placeholder {
  color: #fff;
  opacity: 0.5;
  font-size: 13px;
}

.signup-screen .signup-bx input:hover {
  animation: signupFormRow 1s;
}

.signup-screen .signup-bx button {
  width: 100%;
  display: block;
  background-color: #bbd51a;
  padding: 15px 20px;
  margin: 40px 0 18px 0;
  border-spacing: 1em;
  color: #121212;
  border-radius: 40px;
  box-shadow: 7px 7px 9px #163b23;
  font-size: 15px;
  font-weight: 600;
}

.signup-screen .signup-bx .signup-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #ffffff7a;
}
.signup-screen .signup-bx .signup-option a,
.signup-screen .signup-bx .option a {
  color: #bbd51a !important;
  font-weight: 600;
}

.signup-screen .signup-bx .option {
  text-align: center;
  padding-top: 14px;
}

.error {
  color: #bbd51a !important;
  font-weight: 600;
  font-size: 13px;
  padding: 0 !important;
  margin: 0 !important;
}

.otpField-bx {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.otpField-bx .otp-input {
  width: 50px !important;
  height: 50px !important;
  font-size: 19px !important;
}

.otpField-bx .otp-input::placeholder {
  font-size: 19px !important;
}

@media (max-width: 500px) {
  .signup-screen {
    margin-top: 36px;
  }

  .signup-screen .signup-left {
    padding: 80px 10px 70px 60px;
    background-position: left;
    background-size: contain;
    box-sizing: border-box;
  }

  .signup-screen .signup-left img {
    width: 83%;
  }

  .signup-screen .signup-right {
    padding: 0px 0px 120px 0px;
    box-sizing: border-box;
  }

  .signup-screen .signup-bx {
    width: 80%;
    padding: 35px 25px;
    margin: 20px auto;
  }

  .signup-screen .signin-right {
    padding: 20px 0px 120px 0px;
  }

  .signup-screen .signup-bx label {
    font-size: 11px;
  }

  .signup-screen .signup-bx input {
    padding: 9px 11px;
    box-shadow: 3px 7px 9px #163b23;
    font-size: 11px;
  }

  .signup-screen .signup-bx input::placeholder {
    font-size: 11px;
  }

  .signup-screen .signup-bx button {
    width: 80%;
    display: block;
    padding: 10px 5px;
    margin: 13px auto;
    font-size: 13px;
  }

  .signup-screen .signup-bx .br {
    padding: 3px;
    margin: 0;
  }

  .signup-screen .signup-bx .signup-option {
    flex-direction: column;
    align-items: center;
    font-size: 11px;
  }

  .signup-screen .signup-bx .signup-option span {
    padding: 10px 0 6px 0;
  }
}

@media (min-width: 501px) AND (max-width: 991px) {
  .signup-screen {
    margin-top: 55px;
  }

  .signup-screen .signup-left {
    padding: 80px 10px 70px 60px;
    background-position: left;
    background-size: contain;
    box-sizing: border-box;
  }

  .signup-screen .signup-left img {
    width: 70%;
  }

  .signup-screen .signup-right {
    padding: 0px 0px 70px 0px;
    box-sizing: border-box;
  }

  .signup-screen .signup-bx {
    width: 73%;
    padding: 35px 25px;
    margin: 20px auto;
  }

  .signup-screen .signin-right {
    padding: 20px 0px 80px 0px;
  }
}

@media (min-width: 992px) AND (max-width: 1199px) {
  .signup-screen {
    margin-top: 73px;
  }

  .signup-screen .signup-left {
    padding: 250px 10px 0px 100px;
  }

  .signup-screen .signup-left img {
    width: 80%;
  }

  .signup-screen .signup-left p {
    font-size: 14px;
  }

  .signup-screen .signup-left p span {
    padding-top: 5px;
    font-size: 20px;
  }
  .signup-screen .signin-right {
    padding: 200px 0px 120px 30px;
  }

  .signup-screen .signup-right {
    padding: 60px 0px 60px 40px;
    background: transparent;
  }

  .signup-screen .signup-bx {
    width: 75%;
    padding: 35px 25px;
  }

  .signup-screen .signup-bx label {
    font-size: 11px;
  }

  .signup-screen .signup-bx input {
    padding: 9px 15px;
    font-size: 11px;
  }

  .signup-screen .signup-bx input::placeholder {
    font-size: 12px;
  }

  .signup-screen .signup-bx button {
    padding: 10px 20px;
    margin: 30px 0 10px 0;
    font-size: 13px;
  }

  .signup-screen .signup-bx .signup-option {
    font-size: 11px;
  }

  .signup-screen .signup-bx .signup-option a {
    color: #bbd51a !important;
    font-weight: 600;
  }
}

@media (min-width: 1200px) AND (max-width: 1400px) {
  .signup-screen {
    margin-top: 75px;
  }

  .signup-screen .signup-left {
    padding: 140px 10px 0px 100px;
  }

  .signup-screen .signup-left img {
    width: 77%;
  }

  .signup-screen .signup-left p {
    font-size: 14px;
  }

  .signup-screen .signup-left p span {
    padding-top: 5px;
    font-size: 20px;
  }
  .signup-screen .signin-right {
    padding: 120px 0px 120px 30px;
  }

  .signup-screen .signup-right {
    padding: 60px 0px 60px 40px;
    background: transparent;
  }

  .signup-screen .signup-bx {
    width: 75%;
    padding: 35px 25px;
  }

  .signup-screen .signup-bx label {
    font-size: 11px;
  }

  .signup-screen .signup-bx input {
    padding: 9px 15px;
    font-size: 11px;
  }

  .signup-screen .signup-bx input::placeholder {
    font-size: 12px;
  }

  .signup-screen .signup-bx button {
    padding: 10px 20px;
    margin: 30px 0 10px 0;
    font-size: 13px;
  }

  .signup-screen .signup-bx .signup-option {
    font-size: 11px;
  }

  .signup-screen .signup-bx .signup-option a {
    color: #bbd51a !important;
    font-weight: 600;
  }
}
