.about-team {
  margin: 0 0 0 0;
  padding: 80px 0;
  background-image: url('../../img/brick.jpg');
  position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.about-team .section-header {
  text-align: center;
  width: 60%;
  margin: 0 auto;
}

.about-team h3 {
  text-align: center;
}

.about-team p {
  text-align: center;
  line-height: 1.6em;
  font-size: 14px;
  font-weight: 500;
  color: #ffffffc0;
  margin-bottom: 40px;
}

.about-team .main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px auto;
  width: 100%;
}

.about-team .main .team-bx {
  width: 22.5%;
  background: #26683c;
  padding: 30px 20px 15px 20px;
  border-radius: 12px;
  box-shadow: 1px 5px 12px 5px #000c02d8 !important;
  margin: 15px 0;
}

.about-team .main .team-bx:hover {
  background: #00bfa6;
  transition: 1s ease-in-out;
  transform: scale(1.1);
}

.about-team .main .team-bx img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.about-team .main .team-bx p.name {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.about-team .main .team-bx p.title {
  margin-bottom: 20px;
  padding: 0;
  color: #bbd51a;
  font-size: 14px;
  font-weight: 600;
}
.about-team .main .social-icon {
  display: block;
  margin-left: 14px;
  height: 42px;
  overflow-y: hidden !important;
}
.about-team .main .social-icon a {
  width: 42px;
  height: 42px;
  background: transparent;
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid #fff;
  overflow-y: hidden !important;
}
.about-team .main .social-icon a::before {
  content: '';
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #001f0e;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
  opacity: 0.1;
}
.about-team .main .social-icon a:hover::before {
  transform: scale(1);
}
.about-team .main .social-icon a img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.about-team .main .social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg)
    brightness(95%) contrast(86%);
}

@media (max-width: 500px) {
  .about-team .section-header {
    width: 89%;
  }
  .about-team .main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px auto;
    width: 85%;
  }
  .about-team .main .team-bx {
    width: 100%;
    padding: 50px 20px 25px 20px;
    margin: 12px 0;
  }
  .about-team .main .team-bx img {
    width: 180px;
    height: 180px;
  }
}

@media (min-width: 501px) AND (max-width: 991px) {
  .about-team .section-header {
    width: 89%;
  }
  .about-team .main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px auto;
    width: 85%;
  }
  .about-team .main .team-bx {
    width: 100%;
    padding: 50px 20px 25px 20px;
    margin: 12px 0;
  }
  .about-team .main .team-bx img {
    width: 180px;
    height: 180px;
  }
}

@media (min-width: 992px) AND (max-width: 1199px) {
  .about-team .section-header {
    width: 70%;
  }
  .about-team .main {
    width: 90%;
  }

  .about-team .main .team-bx {
    width: 31%;
  }
}

@media (min-width: 1200px) AND (max-width: 1400px) {
  .about-team .section-header {
    width: 70%;
  }
  .about-team .main {
    width: 100%;
  }
}
